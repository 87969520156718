.site-nav {
  display: block;
  
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: .5rem;
  }

  li {
    &.dropdown.show {
      background: $primary;
      & > a {
        color: $white;
      }
    }
  }

  a, a:link, a:active, a:hover, .current-page-title {
    @extend .nav-link;
    font-family: $nav-font-family;
    font-size: 1rem;
    font-weight: 700;
  }

  a {
    &, &:link, &:active {
      color: $dark-gray;
    }
  }

  a:hover, .current-page-title {
    color: $primary;
  }

  .current {

    &>a, &.dropdown-item {
      &, &:link, &:active {
        color: $primary;
      }
    }
  
    .current-indicator {
      @include sr-only;
    }
  }

  // MEDIUM BREAKPOINT
  @media only screen and (min-width: $breakpoint-md) {  
    ul {
      display: flex;
      justify-content: flex-end;
    }

    li {
      a, a:link, a:active, a:hover, .current-page-title {
        font-size: .8rem;
        font-weight: 500;
        padding: 0.33rem 0.5rem;
      }
    }
  }

  // LARGE BREAKPOINT
  @media only screen and (min-width: $breakpoint-lg) {
    li {
      a, a:link, a:active, a:hover, .current-page-title {
        font-size: 1rem;
        font-weight: 700;
        padding: 0.33rem 0.66rem;
        &.dropdown-item {
          font-size: .8rem;
          font-weight: 200;
        }
      }
    }
  }

  // XL BREAKPOINT
  @media only screen and (min-width: $breakpoint-xl) {
    li {
      a, a:link, a:active, a:hover, .current-page-title {
        font-size: 1.2rem;
      }
    }
  }
}

// nav 1 appears above the banner
// intended for all layouts besides single column
#nav1 {
  @include hide;

  nav ul {

    li {
      border-top: $nav-link-border;
      
      &.dropdown.show {
        border-top: $nav-show-border;
      }

      &.current {
        border-top: $nav-current-border;
      }

      .dropdown-item.current {
        border-left: $nav-current-border;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-md) {
    @include show-block;
    margin-top: 4rem;
  }
}

// nav 2 appears below the banner
// intended for single column layout (think phones)
#nav2 {
  margin-top: 1rem;
  nav ul {
    flex-direction: column;
    li {
      &, .dropdown-menu {
        width: 100%;
      }
      
      border-left: $nav-link-border;

      &.dropdown.show {
        border-left: none;
      }

      &.current, .dropdown-item.current {
        border-left: $nav-current-border;
      }
    }
  }
  @media only screen and (min-width: $breakpoint-md) {
    @include hide;
  }
}
