.page-standard-space-trainer {
  #sst-quotes-1 {
    height: 320px;
  }
  #sst-quotes-2 {
    justify-content: center;
  }
  #sst-awards {
    height: 270px;
  }
}