.contact-form {
  input[type=text], input[type=email], textarea {
    width: 100%;
  }
  textarea {
    min-height: 200px;
  }
}

.contact_php {
  pre {
    white-space: normal;
  }
}