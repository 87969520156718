#branding {
  position: relative;
  height: 100px;
  z-index: 1000;
  a {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(#{$site-rooturl}assets/img/sonalysts-logo.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    text-indent: -9999px;
    height: 100%;
    width: 100%;
  }

  @media only screen and (min-width: $breakpoint-md) {
    position: absolute;
    width: 300px;
    top: 10px;
    left: 50px;
    a {
      background-image: url(#{$site-rooturl}assets/img/sonalysts-brand.png);
    }
  }
}

header {
  @media only screen and (min-width: $breakpoint-xxl) {
    position: relative;
  }
}

