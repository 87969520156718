body {
  background: $gray-200;
}

.container-fluid {
  background: $white;
  min-height: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
  max-width: 1600px;
}

main {
  margin-top: 1rem;
}

address h2 {
  font-size: 1.5rem;
}

img {
  max-width: 100%;
  height: auto;
}

.align-center {
  text-align: center;
}