.carousel-dark {
  .carousel-indicators li {
    background-color: #000;
  }
}

.carousel-indicators-underneath {
  .carousel-indicators {
    bottom: auto;
    top: 100%;
  }
}

.carousel.slide .carousel-item {
  transition: .5s ease-in-out;
}

.carousel-caption {
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 5px;
  background: $carousel-caption-background-color;
  p {
    color: $carousel-caption-color;
    font-family: $carousel-caption-font-family;
    font-size: $carousel-caption-font-size;
    text-shadow: 1px 1px 2px #000;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
