.locations-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  list-style: none;
  padding: 0;
  justify-content: center;

  li {
    margin: 0 10px 10px 0;
    width: 300px;
  }

  h2 {
    font-size: 1rem;
  }

  picture {
    width: 300px;
    display: block;
    height: 185px;
    overflow: hidden;
  }
}