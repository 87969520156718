.blocks article {
  @extend .col;
}

.block-section {
  margin-bottom: 2rem;
  
  .block-section-title,
  .block-section-blocks {
    @extend .row;
  }

  .block-section-title-inner {
    @extend .col;
  }
  &.continuous {
    margin-bottom: 0;
  }
}

.block-content {
  picture > img {
    margin-bottom: 1rem;
  }

  &.primary-col .block-inner {
    @media only screen and (min-width: $breakpoint-md) {
      p {
        text-align: justify;
      }
    }
  }

  .row > section {
    @extend .col-lg;
  }
}

.block-case-studies {
  p, li {
    font-size: $case-study-short-font-size;
    line-height: $case-study-short-line-height;
  }
  .case-study-short {
    @include picture-container(".col-md-4", ".col-xl-3");
    .description {
      @extend .col-md;
    }    
    picture, .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    margin-bottom: 1rem;
  }
}

.block-contacts {
  .contacts {
    @extend .row;

    address {
      @extend .col-sm-auto;
      @extend .col-lg-12;
    }
  }
  &.force-top {
    @media only screen and (min-width: $breakpoint-lg) {
      position: absolute;
      top: 50px;
      right: 0;
    }
  }
}

.block-section-nav {
  ul {
    list-style: none;
    padding: 0;
    @media only screen and (min-width: $breakpoint-md) {
      padding-left: 6rem;
    }
  }
  li {
    font-family: $header-font;
    font-size: 1.2rem;
    @media only screen and (min-width: $breakpoint-xl) {
      font-size: 1.4rem;
    }
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: $breakpoint-md) {
      flex-direction: row;
    }
    div {
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }
    picture, div {
      flex-basis: 50%;
    }
  }
}

.block-examples {
  p, li {
    font-size: $case-study-short-font-size;
    line-height: $case-study-short-line-height;
  }
  .block-example {
    @include picture-container(".col-md-4", ".col-xl-3");
    .description {
      @extend .col-md;
    }
    margin-bottom: 1rem;
  }
}

.block-picture-list {
  .block-picture {
    @include picture-container;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

.block-carousel {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.block-sibling-nav {
  h3 {
    font-weight: bold;
  }
  ul {
    padding: 0;
  }

  li {
    list-style: none;
    padding-left: 1rem;
    border-left: solid 4px $light-blue;
    &.current {
      border-left: solid 4px $blue;
      background: $blue;
      color: $white;
    }
  }
}

.block-video {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.block-collapse {
  .btn-collapse::before {
    content: '-';
  }

  .btn-collapse.collapsed::before {
    content: '+';
  }
}

.block-info-table {
  margin-bottom: 1rem;
  .block-inner > table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    & > tbody {
      & > tr {
        & > th,
        & > td {
          border: none;
          padding: 8px;
        }

        & > th {
          border-left: $info-table-border;
          text-align: right;
          width: 25%;
        }

        & > td {
          border-right: $info-table-border;
        }
      }

      & > tr:first-child {
        & > th {
          border-top-left-radius: $info-table-border-radius;
          border-top: $info-table-border;
        }

        & > td {
          border-top-right-radius: $info-table-border-radius;
          border-top: $info-table-border;
        }
      }

      & > tr:last-child {
        & > th {
          border-bottom-left-radius: $info-table-border-radius;
          border-bottom: $info-table-border;
        }

        & > td {
          border-bottom-right-radius: $info-table-border-radius;
          border-bottom: $info-table-border;
        }
      }

      & > tr:nth-child(even) {
        background: lighten($light-blue, 23%);
      }
    }

    ul {
      padding-left: 15px;
    }
  }
}

.block-benefits {
  .benefits-short {
    @include picture-container(".col-md-7");
    .benefit-points {
      @extend .col-md;
    }    
    picture, .benefit-points {
      display: flex;
      flex-direction: column;
    }
    margin-bottom: 1rem;
  }
}

.block-employee-spotlights {
  display: flex;
  justify-content: center;

  .employee-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 3vw 15vw;
  }

  .employee-item {
    position: relative;
    width: 100%;
    height: 100%;
    
    a:hover .employee-quote,
    a:focus .employee-quote {
      display: inline-flex;
      text-align: left;
    }

    .employee-image {
      height: 100%;
      object-fit: cover;
    }
  
    .employee-caption {
      position: absolute;
      width: 100%;
      padding: 5px;
      color: white;
      background: rgba(0, 0, 0, 0.6);
      bottom: 0;

      .employee-quote {
        display: none;
      }
    
      p {
        text-align: left;
        color: white;
        font-size: .7rem;
        @media screen and (max-width: 610px) and (min-width: 576px) {
          font-size: .6rem;
        }
        margin-bottom: 0;
        line-height: 1.5;
      }
    }
  }
}