@mixin hide {
  display: none;
  visibility: hidden;
}

@mixin show-block {
  display: block;
  visibility: visible;
}

@mixin picture-container ($col-sizes...) {
  @extend .row;
  picture {
    width: 100%;
    @for $i from 0 to length($col-sizes) {
      @extend #{nth($col-sizes, $i + 1)};
    }
    display: block;
    text-align: center;
    img {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      height: auto;
      max-height: 60vh;
    }
  }
}