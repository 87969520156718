.products {
  .block-contacts {
    address:nth-child(2) {
      @media only screen and (min-width: $breakpoint-xl) {
        margin-top: calc(17rem + .38 * (#{$breakpoint-xxl} - 100vw));
      }
      @media only screen and (min-width: $breakpoint-xxl) {
        margin-top: 17rem;
      }
    }
  }
}