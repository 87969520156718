.home {
  #branding a span {
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 65px;
    width: 75%;
    min-height: 100px;
  }

  #carousel {
    margin-left: -15px;
    margin-right: -15px;
  }

  #home-callouts {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }

  .carousel.slide .carousel-item {
    transition: 2s ease-in-out;
  }


  @media only screen and (min-width: $breakpoint-md) {
    #branding {
      width: 400px;
      top: 10px;
      left: 0;
      a {
        background-image: url(#{$site-rooturl}assets/img/sonalysts-logo-background.png);
        span {
          height: 0;
          background-image: none;
        }
      }
    }

    #home-callouts {
      flex-direction: row;
      & > div {
        width: 25%;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-xl) {
    #branding {
      width: 500px;
    }
  }
  

}