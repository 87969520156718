footer {
  padding-bottom: 1rem;
  padding-top: 1rem;
  
  background: $primary;

  ul, address, p {
    margin-bottom: 0;
  }

  li {
    padding: 5px 0;
    &, & a, & a:link, & a:hover, & a:active {
      text-decoration: none;
      color: $white;
    }
    
    display: block;
  }

  * {
    font-family: $nav-font-family;
    color: $white;
  }

  .p-adr a {
    &, & span {
      text-decoration: underline;
      &:hover {
        color: $link-hover-color;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-md) {
   * {
      font-size: .75rem;
    }
  }

  @media only screen and (min-width: $breakpoint-lg) {
    border-top: none; 

    address>span, .p-adr>a {
      display: inline-block;
      padding-left: 7px;
      border-left: solid 1px $white;

      &:first-child {
        border: none;
        padding-left: 0;
      }
    }

    address br {
      display: none;
    }

    li {
      display: inline-block;
      
      &, & a, & a:link, & a:hover, & a:active {
        font-size: .75rem;
      }
    }
  }
}

#footerSocial {
  li {
    display: inline-block;
  }

  a {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: solid 1px #fff;
    border-radius: 8px;

    span {
      @include sr-only;
    }
  }

  @media only screen and (min-width: $breakpoint-md) {
    display: flex;
    flex-direction: row-reverse;
    li {
      margin-left: 5px;
    }
  }
}

#facebook a {
  background-image: url(#{$site-rooturl}assets/img/facebook.png);
}

#linkedin a {
  background-image: url(#{$site-rooturl}assets/img/linkedin.png);
}
