main {
  @extend .row;
}

table {
  margin-bottom: 1rem;
  th, td {
    border: solid 1px $black;
  }
}

th, td {
  padding: 4px;
  vertical-align: top;
  text-align: left;
}

@media only screen and (min-width: $breakpoint-xl) {
  h1 {
    // padding-left: 4rem;
  }

  .article-inner {
    // padding: 0 4rem;
    p {
      text-align: justify;
    }
  }
}