/****** colors ******/
$blue: #426182;
$light-blue: #89b7e8;
$cyan: #00b9f2;
$dark-gray: #333447;
$white: #ffffff;
$black: #000000;
$burnt-orange: #281e19;

// creates overrides to the bootstrap defaults
$primary: $blue;
$secondary: lighten($primary, 40%);

/****** links ******/
$header-link-color: $dark-gray;
$dropdown-link-hover-bg: $secondary;
$link-decoration: underline;

/****** fonts ******/
$font-weight-light: 200;
$font-weight-regular: 400;
$font-weight-heavy: 700;

$body-font: Cambria, Georgia, serif;
$header-font: Verdana, Geneva, sans-serif;

$header-font-family: $header-font;
$header-font-weight: $font-weight-heavy;
$header-color: $dark-gray;
$header-font-sizes: (
  'h1': 1.8rem,
  'h2': 1.4rem,
  'h3': 1.2rem,
  'h4': 1.1rem,
  'h5': 1rem,
  'h6': .9rem,
);

$body-font-family: $body-font;
$body-line-height: 1.8;
$body-font-size: 1rem;
$body-font-weight: $font-weight-light;
$body-font-color: $dark-gray;

/****** nav ******/
$nav-font-family: $header-font;
$nav-font-size: 1.2em;
$nav-font-weight: 700;

$nav-link-border: solid 4px $white;
$nav-current-border: solid 4px $secondary;
$nav-show-border: solid 4px $primary;

$nav-link-padding-x: .66rem;
$nav-link-padding-y: .33rem;

/** Breadcrumbs **/
$breadcrumb-font-family: $header-font;
$breadcrumb-font-size: .9rem;
$breadcrumb-font-weight: $font-weight-regular;

/** Carousel **/
$carousel-caption-font-family: $header-font;
$carousel-caption-color: $white;
$carousel-caption-background-color: rgba(0, 0, 0, 0.5);
$carousel-caption-font-size: .9rem;

/** Contacts **/
$contact-font-size: .9rem;
$contact-line-height: 1.6;

/** Case Studies **/
$case-study-short-font-size: .9rem;
$case-study-short-line-height: 1.6;

/** Info Table **/
$info-table-border: solid 1px #aaa;
$info-table-border-radius: 4px;


